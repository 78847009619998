/* Import theme variables */
@import "_override";


/* PART 1: card grid for entry.md page */
.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.content-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  
  // Dark mode styles
  [theme=dark] & {
    background: $global-background-secondary-color-dark;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
    
    [theme=dark] & {
      box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    }
  }
  
  h3 {
    color: #3a7ea2;
    border-bottom: 2px solid #FF5D62;
    padding-bottom: 8px;
    margin-top: 0;
    display: flex;
    align-items: center;
    
    [theme=dark] & {
      color: $h2-color-dark;
      border-bottom: 2px solid $h2-color-dark;
    }
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 2px;
    
    [theme=dark] & {
      color: $global-font-color-dark;
    }
    
    a {
      color: $single-link-color;
      text-decoration: none;
      
      [theme=dark] & {
        color: $single-link-color-dark;
      }
      
      &:hover {
        color: $single-link-hover-color;
        text-decoration: underline;
        
        [theme=dark] & {
          color: $single-link-hover-color-dark;
        }
      }
    }
  }
}

.card-icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  
  svg {
    stroke: #3a7ea2;
    
    [theme=dark] & {
      stroke: $h2-color-dark;
    }
  }
}

@media (max-width: 768px) {
  .content-grid {
    grid-template-columns: 1fr;
  }
}
/* PART 2: Second iteration */



/* Entry Page Styles */

/* Page Title and Subtitle */
.page-title {
  font-size: 2.5rem;
  text-align: center;
  margin: 1.5rem 0 0.5rem;
  line-height: 1em;
  color: $global-background-secondary-color-dark;
}

[theme=dark] .page-title {
  color: #DCD7BA;
}

.page-subtitle {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  margin: 0 0 2rem;
  color: #FF5D62;
}

[theme=dark] .page-subtitle {
  color: #ff8c8e;
}

/* Hero Section */
.hero-container {
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem;
  gap: 2rem;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
}

[theme=dark] .hero-container {
  box-shadow: 0 8px 30px rgba(0,0,0,0.3);
  background: rgba(50,50,60,0.3);
}

.hero-content {
  flex: 1;
  padding: 2.5rem;
}

.hero-image {
  flex: 1;
  min-height: 360px;
  position: relative;
  overflow: hidden;
}

.hero-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-title {
  font-size: 2.5rem;
  margin: 0;
  background: linear-gradient(135deg, #3a7ea2, #FF5D62);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

[theme=dark] .hero-title {
  background: linear-gradient(135deg, #658594, #FF5D62);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin: 0.5rem 0 1rem;
  font-weight: 600;
  color: #3a7ea2;
}

[theme=dark] .hero-subtitle {
  color: #DCD7BA;
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  max-width: 550px;
}

.hero-cta {
  display: flex;
  gap: 1rem;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}

.hero-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.hero-button.primary {
  background-color: #FF5D62;
  color: white;
}

.hero-button.primary:hover {
  background-color: #d44146;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.hero-button.secondary {
  background-color: white;
  color: #3a7ea2;
  border: 2px solid #3a7ea2;
}

[theme=dark] .hero-button.secondary {
  background-color: rgba(50,50,60,0.8);
  color: #658594;
  border: 2px solid #658594;
}

.hero-button.secondary:hover {
  background-color: #3a7ea2;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

[theme=dark] .hero-button.secondary:hover {
  background-color: #658594;
  color: #222;
}

/* Hero Social Links */
.hero-social {
  margin-top: 1.5rem;
}

.hero-social .links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
}

/* Welcome Message */
.welcome-message {
  // background: rgba(0,0,0,0.02);
  background: linear-gradient(135deg, rgba(49, 80, 170, 0.12) 0%, rgba(210, 126, 153, 0.12) 100%);
  border-left: 5px solid #FF5D62;
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: 0 8px 8px 0;
  font-size: 1.1rem;
  line-height: 1.6;
}

[theme=dark] .welcome-message {
  background: rgba(255,255,255,0.05);
}

.highlight-link {
  color: #FF5D62;
  font-weight: 600;
  transition: color 0.2s;
}

.highlight-link:hover {
  color: #d44146;
  text-decoration: underline;
}

/* Section Heading */
.section-heading {
  text-align: center;
  font-size: 2rem;
  margin: 3rem 0 2rem;
  position: relative;
}

.section-heading:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(135deg, #3a7ea2, #FF5D62);
  border-radius: 2px;
}

[theme=dark] .section-heading:after {
  background: linear-gradient(135deg, #658594, #FF5D62);
}

.heading-accent {
  color: #FF5D62;
}

/* Navigation Links */
.navigation-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 3rem 0;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  background: linear-gradient(135deg, rgba(49, 80, 170, 0.08) 0%, rgba(210, 126, 153, 0.08) 100%);
  box-shadow: 0 2px 10px #2c678d;
  transition: all 0.3s ease;
  width: 40%;
  max-width: 300px;
}

[theme=dark] .nav-link {
  background-color: rgba(50,50,60,0.8);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.nav-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0,0,0,0.12);
}

.nav-icon {
  font-size: 2rem;
  margin-right: 1rem;
}

.nav-content {
  display: flex;
  flex-direction: column;
}

.nav-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

[theme=dark] .nav-title {
  color: #f0f0f0;
}

.nav-desc {
  font-size: 0.9rem;
  color: #666;
}

[theme=dark] .nav-desc {
  color: #aaa;
}

/* Newsletter Section */
.newsletter-container {
  background: linear-gradient(135deg, rgba(255,93,98,0.1) 0%, rgba(210,126,153,0.1) 100%);
  border-radius: 12px;
  padding: 2.5rem;
  margin: 3rem 0;
  text-align: center;
  box-shadow: 0 6px 20px rgba(255,93,98,0.15);
}

[theme=dark] .newsletter-container {
  background: linear-gradient(135deg, rgba(255,93,98,0.1) 0%, rgba(210,126,153,0.1) 100%);
  box-shadow: 0 6px 20px rgba(255,93,98,0.15);
}

.newsletter-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: #333;
}

[theme=dark] .newsletter-content h2 {
  color: #DCD7BA;
}

.accent-text {
  color: #FF5D62;
}

[theme=dark] .accent-text {
  color: #FF5D62;
}

.newsletter-button {
  display: inline-block;
  background: linear-gradient(135deg, #FF5D62, #D27E99);
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  text-decoration: none;
  margin: 1rem 0;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(255,93,98,0.2);
}

.newsletter-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(255,93,98,0.3);
}

.newsletter-note {
  font-size: 0.9rem;
  opacity: 0.9;
}

.newsletter-note a {
  color: #FF5D62;
  text-decoration: underline;
}

[theme=dark] .newsletter-note a {
  color: #D27E99;
}

.newsletter-note a:hover {
  color: #D27E99;
}

[theme=dark] .newsletter-note a:hover {
  color: #FF5D62;
}

/* Featured Book Section */
.featured-book {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 3rem 0;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(49, 80, 170, 0.08) 0%, rgba(210, 126, 153, 0.08) 100%);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.08);
}

[theme=dark] .featured-book {
  background: linear-gradient(135deg, rgba(101, 133, 148, 0.15) 0%, rgba(255, 93, 98, 0.1) 100%);
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

.book-content {
  flex: 3;
}

.book-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: #3a7ea2;
}

[theme=dark] .book-content h2 {
  color: #DCD7BA;
}

.book-tagline {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #FF5D62;
}

.book-features {
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
}

.book-features li {
  margin-bottom: 0.8rem;
  position: relative;
  padding-left: 1.5rem;
}

.book-features li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #FF5D62;
  font-weight: bold;
}

.book-button {
  display: inline-block;
  background: linear-gradient(135deg, #FF5D62, #D27E99);
  color: white;
  padding: 0.7rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.book-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255,93,98,0.25);
}

.book-cover {
  flex: 2;
  text-align: center;
}

.book-cover img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
  transition: transform 0.3s ease;
}

.book-cover img:hover {
  transform: translateY(-5px) rotate(1deg);
  box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

/* Content Card Enhancements */
.content-card {
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
  border-top: 5px solid transparent;
  background: linear-gradient(135deg, rgba(49, 80, 170, 0.08) 0%, rgba(210, 126, 153, 0.08) 100%);
  box-shadow: 0 1px 2px #2c678d;
}

.content-card:nth-child(1) { border-top-color: #FF5D62; }
.content-card:nth-child(2) { border-top-color: #3a7ea2; }
.content-card:nth-child(3) { border-top-color: #ffc658; }
.content-card:nth-child(4) { border-top-color: #58c4ff; }
.content-card:nth-child(5) { border-top-color: #ff8c58; }
.content-card:nth-child(6) { border-top-color: #84c758; }

.content-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

[theme=dark] .content-card:hover {
  box-shadow: 0 8px 25px rgba(0,0,0,0.25);
}

.content-card h3 {
  font-size: 1.3rem;
}

.card-icon {
  font-size: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero-container {
    flex-direction: column;
  }
  
  .hero-image {
    width: 100%;
    min-height: 250px;
    order: -1;
  }
  
  .hero-content {
    text-align: center;
    padding: 2rem 1.5rem;

  }
  .hero-cta {
    justify-content: center;
  }
  
  .hero-social .links {
    justify-content: center;
  }
  
  .featured-book {
    flex-direction: column;
    padding: 1.5rem;
  }
  
  .book-cover {
    margin-top: 1.5rem;
  }
  
  .navigation-links {
    flex-direction: column;
    align-items: center;
  }
  
  .nav-link {
    width: 80%;
    max-width: none;
  }
}

@media (max-width: 640px) {
  .page-title {
    font-size: 2rem;
  }
  
  .page-subtitle {
    font-size: 1.2rem;
  }
  
  .hero-title {
    font-size: 2.2rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .hero-description {
    font-size: 1rem;
  }
  
  .hero-cta {
    flex-direction: column;
    gap: 0.8rem;
  }
  
  .section-heading {
    font-size: 1.6rem;
  }
  
  .featured-book {
    text-align: center;
  }
  
  .book-features {
    text-align: left;
  }
  
  .newsletter-container {
    padding: 1.5rem;
  }
  
  .newsletter-content h2 {
    font-size: 1.5rem;
  }
}


